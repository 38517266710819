
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="样品销毁" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <a-form-model  :model="form" >
          <a-row>
            <a-col :span=12>
              <a-form-model-item label="样品类型" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="采集时间" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="样品编号" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="保管地点" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="采集经度" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="是否留样" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-radio-group >
                  <a-radio  value="1">是</a-radio>
                  <a-radio  value="2">否</a-radio>
                </a-radio-group>
              </a-form-model-item>

            </a-col>
            <a-col :span=12>
              <a-form-model-item label="样品名称" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="接收时间" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="采集人" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="采集地点" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="采集纬度" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-input/>
              </a-form-model-item>
              <a-form-model-item label="是否领取" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-radio-group >
                  <a-radio  value="1">是</a-radio>
                  <a-radio  value="2">否</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span=24>
              <a-form-model-item label="备注" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-textarea  rows="3"/>
              </a-form-model-item>
              <a-form-model-item label="" :label-col="{ span: 6, offset: 0 }" :wrapper-col="{ span:18, offset: 4 }">
                <a-space>
                  <a-button type="primary" >确认销毁</a-button>
                </a-space>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-drawer>

  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close'],
    data() {
      return {
        form:{}

      }
    },
    mounted() {

    },
    methods: {


      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>

</style>
