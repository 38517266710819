<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="样品留样" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>

                <a-form-model>
                    <a-row>
                        <a-col :span=12>
                            <a-form-model-item label="样品类型" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.type_name}}
                            </a-form-model-item>
                            <a-form-model-item label="样品编号" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.in_number}}
                            </a-form-model-item>
                            <a-form-model-item label="采集时间" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.pick_time}}
                            </a-form-model-item>
                            <a-form-model-item label="采集经度" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.jing_dot}}
                            </a-form-model-item>
                            <a-form-model-item label="采集地点" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.pick_space}}
                            </a-form-model-item>
                        </a-col>
                        <a-col :span=12>
                            <a-form-model-item label="样品名称" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.name}}
                            </a-form-model-item>
                            <a-form-model-item label="接收时间" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.get_time}}
                            </a-form-model-item>
                            <a-form-model-item label="采集人" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.caiji_name}}
                            </a-form-model-item>
                            <a-form-model-item label="采集纬度" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                {{Reserve_info.wei_dot}}
                            </a-form-model-item>

                        </a-col>
                    </a-row>
                </a-form-model>
                <a-form-model :model="form">
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item label="留样时间" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">

                                <a-date-picker v-model="form.reserve_time" format="YYYY-MM-DD HH:mm:ss"
                                               valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="到期时间" :label-col="{ span: 8, offset: 0 }"
                                               :wrapper-col="{ span:16, offset: 0 }">
                                <a-date-picker v-model="form.expire_time" format="YYYY-MM-DD HH:mm:ss"
                                               valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-model-item label="" :label-col="{ span: 6, offset: 0 }"
                                               :wrapper-col="{ span:18, offset: 4 }">
                                <a-space>
                                    <a-button type="primary" @click="Set_liuyangitem()">确认留样</a-button>
                                </a-space>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-form-model>
            </div>
        </a-drawer>

    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'Reserve_info', 'Reserve_time'],
        data() {
            return {
                form: {
                    reserve_time: '',
                    expire_time: '',
                }
            }
        },
        mounted() {
            console.log(this.Reserve_info)
            if (this.form.reserve_time == '') {
                this.form.reserve_time = this.formatDateTime(new Date() / 1)
            }
        },
        methods: {
            Set_liuyangitem() {
                let data = {
                    id: this.Reserve_info.id,
                    liuyang_status: 2,
                    expire_time: this.form.expire_time,
                    reserve_time: this.form.reserve_time,
                }
                //console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Set_liuyangitem'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('已留样')
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            formatDateTime(inputTime) {
                var date = new Date(inputTime);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('0' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
            },

            onClose() {
                this.close();
            },
        }
    }
</script>
<style scoped>

</style>
