
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="拒收退回记录" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <table class="layui-table">
          <thead>
          <tr>
            <th>时间</th>
            <th>操作人</th>
            <th>拒收/退回原因</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,key) in Get_jushoutuihuilist_list">
            <td>{{item.create_time}}</td>
            <td>{{item.create_name}}</td>
            <td>{{item.record_text}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </a-drawer>
  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close','send_info'],
    data() {
      return {
        info:{
          id:'',
        },
        Get_jushoutuihuilist_list:[],
      }
    },
    mounted() {
        console.log(this.send_info.id)
        this.Get_jushoutuihuilist()
    },
    methods: {
      Get_jushoutuihuilist(){
        this.$sa0.post({
          url: this.$api('Get_jushoutuihuilist'),
          data: {
            spec_item_id:this.send_info.id
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.Get_jushoutuihuilist_list = response.data.list;
              console.log(this.Get_jushoutuihuilist_list);
              //this.count = Number(response.data.result.count);//分页
              //this.pagesize =  Number(response.data.result.pagesize);//分页
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      },
      // 定义 组件 自身的关闭函数
      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>

</style>
