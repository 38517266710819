
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="样品流转记录" width="32%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>

        <a-timeline>
          <a-timeline-item color="green" v-for="(item,key) in Jieshou_itemrecord_list">
            <p>{{item.record_text}}</p>
            <p>处理人：{{item.create_name}}</p>
            <p>处理时间：{{item.create_time}}</p>
          </a-timeline-item>
        </a-timeline>

      </div>
    </a-drawer>
  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close','info'],
    data() {
      return {
        Jieshou_itemrecord_list:[],
      }
    },
    mounted() {
      this.Jieshou_itemrecord()

    },
    methods: {

      Jieshou_itemrecord(){
        console.log(this.info.id)
        this.$sa0.post({
          url: this.$api('Jieshou_itemrecord'),
          data: {
            spec_item_id:this.info.id
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.Jieshou_itemrecord_list = response.data.list;
              console.log(this.Jieshou_itemrecord_list);
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      },
      // 定义 组件 自身的关闭函数
      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>

</style>
