
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="样品申领" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <a-form-model >
          <a-row>
            <a-col :span=12 >
              <a-form-model-item label="样品申领人" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_specreceive_list.receive_name}}
              </a-form-model-item>
              <a-form-model-item label="样品当前状态" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{this.YP_status}}
              </a-form-model-item>
            </a-col>
            <a-col :span=12>
              <a-form-model-item label="样品申领时间" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_specreceive_list.create_time}}
              </a-form-model-item>
              <a-form-model-item label="是否留样" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_specreceive_list.liuyang_status_turn}}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span=12>
              <a-form-model-item label="是否领取" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_specreceive_list.lingqu_status_turn}}
              </a-form-model-item>
            </a-col>
          </a-row>
<!--          <a-form-model-item label="" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 4 }">-->
<!--            <p>{{Get_specreceive_list.receive_desc}}</p>-->
<!--          </a-form-model-item>-->
          </a-form-model>

          <a-form-model  :model="form" >
          <a-row>
            <a-col :span=24>
              <a-form-model-item label="备注" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-textarea  rows="3" v-model="form.remark"/>
              </a-form-model-item>
              <a-form-model-item label="拒绝原因" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <a-textarea  rows="3" v-model="form.reason"/>
              </a-form-model-item>
              <a-form-model-item label="" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:18, offset: 4 }">
                <a-space>
                  <a-button type="primary" @click="Set_lingquitem(1)">同意领取</a-button>
                  <a-button type="primary" @click="Set_lingquitem(2)">拒绝领取</a-button>
                </a-space>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

      </div>
    </a-drawer>

  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close','Apply_info','iApply_info','Apply_time'],
    data() {
      return {
        YP_status:'',
        figure:'',
        Get_specreceive_list:[],
        form:{
          remark:'',
          reason:'',
        }
      }
    },
    mounted() {
      this.Get_specreceive()
      console.log(this.iApply_info.id)
      this.YP_status = this.Apply_info.status_trun
    },
    methods: {
      Get_specreceive() {
        this.$sa0.post({
          url: this.$api('Get_specreceive'),
          data: {
            spec_receive_id: this.iApply_info.id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.Get_specreceive_list = response.data.spec_receive;
              console.log(this.Get_specreceive_list);
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      },
      Set_lingquitem(c){
        let data = {
          id:this.iApply_info.id,//申领记录ID
          lingqu_status:c,//确认领取、拒绝领取
          receive_type:this.Get_specreceive_list.receive_type,//是否领取过
          spec_item_id:this.Get_specreceive_list.spec_item_id,//样品ID
          reason:this.form.reason,
          remark:this.form.remark,
        }
        console.log(data.lingqu_status)
        console.log(JSON.stringify(data))
        this.$sa0.post({
          url: this.$api('Set_lingquitem'),
          data: data,
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (res) => {
              if(c === 1){
                layer.msg('已同意领取')
              }else {
                layer.msg('已拒绝领取')
              }
              this.close()
            },
            error: (res) => {
              layer.msg(res.message)
            }
          })
        })
      },

      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>

</style>
