
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="确认样品退回" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <a-form-model v-bind = Get_tuihuireceive_list>
          <a-row>
            <a-col :span=12>
              <a-form-model-item label="样品类型" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.spec_type_name}}
              </a-form-model-item>
              <a-form-model-item label="采集时间" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.pick_time}}
              </a-form-model-item>
              <a-form-model-item label="样品编号" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.in_number}}
              </a-form-model-item>
              <a-form-model-item label="保管地点" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.baoguan_address}}
              </a-form-model-item>
              <a-form-model-item label="采集经度" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.jing_dot}}
              </a-form-model-item>
              <a-form-model-item label="是否留样" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.liuyang_status_trun}}
              </a-form-model-item>

            </a-col>
            <a-col :span=12>
              <a-form-model-item label="样品名称" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.name}}
              </a-form-model-item>
              <a-form-model-item label="接收时间" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.get_time}}
              </a-form-model-item>
              <a-form-model-item label="采集人" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.caiji_name}}
              </a-form-model-item>
              <a-form-model-item label="采集地点" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.pick_space}}
              </a-form-model-item>
              <a-form-model-item label="采集纬度" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.wei_dot}}
              </a-form-model-item>
              <a-form-model-item label="是否领取" :label-col="{ span: 8, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                <div class="lingqu">
                  {{Get_tuihuireceive_list.lingqu_status_trun}}
                  <div class="lingqu-list">
                      <p v-for="(iitem, key0) in Get_tuihuireceive_list.lingqu_list">领取人：{{iitem.lingqu_name}}, 领取时间：{{iitem.do_time}}</p>
                  </div>
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
          <a-form-model>
          <a-row>
            <a-col :span=24>
              <a-form-model-item label="退回原因" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:16, offset: 0 }">
                {{Get_tuihuireceive_list.reason}}
              </a-form-model-item>
              <a-form-model-item label="" :label-col="{ span: 6, offset: 0 }" :wrapper-col="{ span:18, offset: 4 }">
                <a-space>
                  <a-button type="primary" @click="Set_tuihuireceive()">确认退回</a-button>
                </a-space>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-drawer>

  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close','SendBacki_info','SendBacki_time'],
    data() {
      return {
        Get_tuihuireceive_list:[],

      }
    },
    mounted() {
      this.Get_tuihuireceive()
    },
    methods: {
      Set_tuihuireceive(){
        let data = {
          spec_receive_id: this.SendBacki_info.id,
        }
        //console.log(data.spec_receive_id)
        //console.log(JSON.stringify(data))
        this.$sa0.post({
          url: this.$api('Set_tuihuireceive'),
          data: data,
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (res) => {
                layer.msg('已确认退回')
                this.close()
            },
            error: (res) => {
              layer.msg(res.message)
            }
          })
        })
      },

      Get_tuihuireceive() {
        console.log(this.SendBacki_info.id);
        this.$sa0.post({
          url: this.$api('Get_tuihuireceive'),
          data: {
            spec_receive_id: this.SendBacki_info.id,
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.Get_tuihuireceive_list = response.data.spec_item;
              console.log(this.Get_tuihuireceive_list);
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      },

      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>
.lingqu{position: relative; width: 100%; height: 100%}
.lingqu-list{display: none}
.lingqu:hover .lingqu-list{
  cursor: pointer;
  display: block;
  position: absolute;
  top: 32px;
  right:0;
  width: 260px;
  height: 260px;
  border: solid 1px #efefef;
  z-index: 9999;
}
</style>
