
<template>
  <div>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="样品接收" width="36%" placement="right" :closable="true" :visible="visible" @close="onClose">
      <div>
        <a-form-model>
          <a-form-model-item label="样品类型" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10, offset: 0 }">
            {{sample_info.type_name}}
          </a-form-model-item>
          <a-form-model-item label="样品名称" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10, offset: 0 }">
            {{sample_info.name}}
          </a-form-model-item>
          <a-form-model-item label="样品编号" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10, offset: 0 }">
            {{sample_info.in_number}}
          </a-form-model-item>
          <a-form-model-item label="采集时间" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10, offset: 0 }">
            {{sample_info.pick_time}}
          </a-form-model-item>
          <a-form-model-item label="采集地点" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10, offset: 0 }">
            {{sample_info.pick_space}}
          </a-form-model-item>
          <a-form-model-item label="采集经度" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10, offset: 0 }">
            {{sample_info.jing_dot}}
          </a-form-model-item>
          <a-form-model-item label="采集纬度" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10, offset: 0 }">
            {{sample_info.wei_dot}}
          </a-form-model-item>
          <a-form-model-item label="" :label-col="{ span: 4, offset: 0 }" :wrapper-col="{ span:10, offset: 4 }">
            <a-space>
              <a-button type="primary" @click="Set_jieshouitem()">确认接收</a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-drawer>

  </div>
</template>
<script>
  /***
   Name: example3
   Code: sa0ChunLuyu
   Time: 2021/10/16 09:47
   Remark: 编辑抽屉示例的抽屉组件
   */
  export default {
    // 接收 显示变量 和 关闭函数
    props: ['visible', 'close','sample_info','sample_time'],
    data() {
      return {
      }
    },
    mounted() {

    },
    methods: {
      Set_jieshouitem(){
        let data = {
          id: this.sample_info.id,
          type_name:this.sample_info.type_name,
          spec_type:this.sample_info.spec_type,
          spec_types:this.sample_info.spec_types,
          name:this.sample_info.name,
          in_number:this.sample_info.in_number,
          pick_time:this.sample_info.pick_time,
          pick_space:this.sample_info.pick_space,
          jing_dot:this.sample_info.jing_dot,
          wei_dot:this.sample_info.wei_dot,
          status:4,
        }
        //console.log(data)
        console.log(JSON.stringify(data))
        this.$sa0.post({
          url: this.$api('Set_jieshouitem'),
          data: data,
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (res) => {
              layer.msg('已接收')
              this.close()
            },
            error: (res) => {
              layer.msg(res.message)
            }
          })
        })
      },

      onClose() {
        this.close();
      },
    }
  }
</script>
<style scoped>

</style>
