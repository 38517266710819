import api from "../api/api";

let print={};
print.getImg=(item)=> {
    let data = {
        i: item.demo_num,
        pw: item.paper_w,
        ph: item.paper_h,
        bs: Number(item.isshow_barcode) !== 2,
        qs: Number(item.isshow_qrcode) !== 2,
        ts: Number(item.isshow_text) !== 2,
    }

    if (Number(item.isshow_barcode) !== 2) {
        data.bw = item.barcode_w
        data.bh = item.barcode_h
        data.btm = item.barcode_top
        data.blm = item.barcode_left
    }

    if (Number(item.isshow_qrcode) !== 2) {
        data.qw = item.qrcode_w
        data.qh = item.qrcode_h
        data.qtm = item.qrcode_top
        data.qlm = item.qrcode_left
    }
    if (Number(item.isshow_text) !== 2) {
        data.tv = item.demo_text
        data.tsi = item.text_size
        data.ttm = item.text_top
        data.tlm = item.text_left
    }
    let q_arr = [];
    // let url = 'http://aaa.aaa.aaa?aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
    // ['tsi=1','ttm=1','aaa=1','aaa=1','aaa=1']
    for (let i in data) {
        q_arr.push(`${i}=${data[i]}`)
    }
    let q_str = q_arr.join('&')
    // 'aaa=1&aaa=2&aaa=2&aaa=2&aaa=2&aaa=2'
    let url = api('qrCode') + '?' + q_str
    return url
}
export default print;